// import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";
import star from '../images/Star.svg'
import shield from '../images/Shield.svg'
import send from '../images/Send.svg'
import people01 from '../images/people01.png'
import people02 from '../images/people02.png'
import people03 from '../images/people03.png'

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Rewards",
    delay:0,
    y:-100,
    content:
      "The best credit cards offer some tantalizing combinations of promotions and prizes",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    delay:.2,
    y:-75,
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Balance Transfer",
    delay:.4,
    y:-50,
    content:
      "A balance transfer credit card can save you a lot of money in interest charges.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "The Digital Bank supports clients engaged in a variety of global capital market activities, bridging importers & exporters, businesses, and financiers.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "I’m very impressed with the assistance provided by customer service at Woohoo Pay! Thank you so much to the customer service team at Woohoo Pay for making this process easy!",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "Whenever I have a question or need additional help, I’m able to get in contact with someone and get an answer quickly. I had a particularly positive experience this week and wanted to share.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

// export const stats = [
//   {
//     id: "stats-1",
//     title: "User Active",
//     value: "3800+",
//   },
//   {
//     id: "stats-2",
//     title: "Trusted by Company",
//     value: "230+",
//   },
//   {
//     id: "stats-3",
//     title: "Transaction",
//     value: "$230M+",
//   },
// ];

// export const footerLinks = [
//   {
//     title: "Useful Links",
//     links: [
//       {
//         name: "Content",
//         link: "https://www.hoobank.com/content/",
//       },
//       {
//         name: "How it Works",
//         link: "https://www.hoobank.com/how-it-works/",
//       },
//       {
//         name: "Create",
//         link: "https://www.hoobank.com/create/",
//       },
//       {
//         name: "Explore",
//         link: "https://www.hoobank.com/explore/",
//       },
//       {
//         name: "Terms & Services",
//         link: "https://www.hoobank.com/terms-and-services/",
//       },
//     ],
//   },
//   {
//     title: "Community",
//     links: [
//       {
//         name: "Help Center",
//         link: "https://www.hoobank.com/help-center/",
//       },
//       {
//         name: "Partners",
//         link: "https://www.hoobank.com/partners/",
//       },
//       {
//         name: "Suggestions",
//         link: "https://www.hoobank.com/suggestions/",
//       },
//       {
//         name: "Blog",
//         link: "https://www.hoobank.com/blog/",
//       },
//       {
//         name: "Newsletters",
//         link: "https://www.hoobank.com/newsletters/",
//       },
//     ],
//   },
//   {
//     title: "Partner",
//     links: [
//       {
//         name: "Our Partner",
//         link: "https://www.hoobank.com/our-partner/",
//       },
//       {
//         name: "Become a Partner",
//         link: "https://www.hoobank.com/become-a-partner/",
//       },
//     ],
//   },
// ];

// export const socialMedia = [
//   {
//     id: "social-media-1",
//     icon: instagram,
//     link: "https://www.instagram.com/",
//   },
//   {
//     id: "social-media-2",
//     icon: facebook,
//     link: "https://www.facebook.com/",
//   },
//   {
//     id: "social-media-3",
//     icon: twitter,
//     link: "https://www.twitter.com/",
//   },
//   {
//     id: "social-media-4",
//     icon: linkedin,
//     link: "https://www.linkedin.com/",
//   },
// ];

// export const clients = [
//   {
//     id: "client-1",
//     logo: airbnb,
//   },
//   {
//     id: "client-2",
//     logo: binance,
//   },
//   {
//     id: "client-3",
//     logo: coinbase,
//   },
//   {
//     id: "client-4",
//     logo: dropbox,
//   },
// ];